body {
  padding: 0;
  font-family: 'Fira Sans', sans-serif;
}

input, button, textarea, select {
  font-family: 'Fira Sans', sans-serif;
}

.input-group-text {
  background-color: white;
}

.nav.nav-tabs .nav-item a {
  color: inherit;
}
